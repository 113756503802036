import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

export const AnimateSlideRight = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animationControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      animationControls.start("visible");
    }
  }, [isInView]);
  return (
    <Motiondiv ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, x: -75 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={animationControls}
        transition={{ duration: 0.6, delay: 0.45 }}
      >
        {children}
      </motion.div>
    </Motiondiv>
  );
};
const Motiondiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  p {
    text-align: center;
  }
`;
