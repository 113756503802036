import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, TempContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "Components/Assets/logo.png";
import heroImg from "Components/Assets/heroImg.png";
import { useMediaQuery } from "hooks";
import { VideoPlayer } from "Components";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import { AnimatePop } from "./AnimatePop";
import { AnimateSlideRight } from "./AnimateSlideRight";
import { AnimateSlideLeft } from "./AnimateSlideLeft";
import { AnimateRotate } from "./AnimateRotate";
import { CookieButton } from "Layout";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const downloadButton = useRef(null);
    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };
    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event?.meta?.storageDirectory;

    const isMobile = useMediaQuery();
    // logo
    const { brand: brandLogo, hero, link } = event?.logos;
    let brandLogoImg = `${api}/o/${storageDirectory}%2F${brandLogo?.image}?alt=media`;
    //  let heroImg = `${api}/o/${storageDirectory}%2F${hero?.image}?alt=media`;
    if (registered) formStep = event?.register.sweeps.length - 1;

    const scrollToSection = (elementRef) => {
      elementRef.current.scrollIntoView();
    };

    const {
      footerHeading,
      maxPolicy,
      brandDisclaimer,
      downloadLink,
      downloadLinkSp,
      privacyPolicy,
      MAXpage,
    } = event.footer;

    // registration content
    const {
      heading,
      subHeading,
      subHeadingPostReg,
      subBodyPostSweep,
      body,
      bodyPostSweep,
      bodyPostReg,
      formHeading,
      formLegal,
      submit,
      pageCheck,
    } = sweeps
      ? event?.register.sweeps[formStep]
      : event?.register.postSweeps[formStep];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event?.register;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event?.callToActions.musicVideo;
    let videoBgDesktop = `${api}/o/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/o/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event?.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event?.callToActions.brand.background;
    let brandBgDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;
    console.log(link);
    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions.brand.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      headingMobile: postCampaignHeadingMobile,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
      heroImage,
    } = event?.postCampaign;
    let heroImg = `${api}/${storageDirectory}/${heroImage}?alt=media`;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event?.callToActions.brandSecondary;

    const { video: heroVideo } = event?.callToActions?.heroVideo;
    // const desktopVideoUrls = "https://storage.googleapis.com/whitelabel-set-live.appspot.com/632-mcdonalds-los-parras/desktop/manifest.m3u8"
    // const desktopVideoUrls = desktop?.map(
    //   (url) => `${api}/${storageDirectory}/${url}`
    // );
    //  const desktopVideoUrls = `${api}/${storageDirectory}/${desktop[0]}`;
    //  const mobileVideoUrls = `${api}/${storageDirectory}/${mobile[0]}`;

    //   console.log("URL",desktopVideoUrls)
    // const mobileVideoUrls = mobile?.map(
    //   (url) => `${api}/o/${storageDirectory}%2F${url}?alt=media`
    // );

    //  const currentVideoUrl = isMobile ? desktopVideoUrls : mobileVideoUrls;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    // if (!loaded) {
    //   return null;
    // }
    console.log("load");
    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        //  showFooter
        registered={registered}
        postCampaign={postCampaign}
      >
        <Container>
          <Content>
            <CTA>
              <AnimateSlideRight>
                <ItemsImg
                  src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/beach-gear.png"
                  alt="sunglasses and other beach things"
                />
              </AnimateSlideRight>
              {!loaded ? (
                <div style={{ height: 500 }}></div>
              ) : (
                <CTAContent>
                  <AnimatePop>
                    <LogoBox>
                      <StyledLink
                        href={brandLogo.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          linkClickHandler(
                            brandLogo.link,
                            "McDonald’s Homepage",
                            "header"
                          )
                        }
                      >
                        {brandLogoImg && <Logo src={logo} />}
                      </StyledLink>
                    </LogoBox>
                  </AnimatePop>
                  <AnimatePop>
                    <Wrapper>
                      <AnimateRotate style={{ alignSelf: "flex-end" }}>
                        <img
                          style={{
                            width: 40,
                            alignSelf: "flex-end",
                            marginLeft: "auto",
                          }}
                          src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/star1.png"
                          alt="star"
                        />
                      </AnimateRotate>
                      <Heading style={{ textAlign: "center" }}>
                        Splash into summer
                        <br /> with a Frozen Drink
                      </Heading>
                      <Body style={{ margin: "auto" }}>
                        Turn up the music and turn down the heat with McDonald’s
                        and Willie Jones. Watch the full video below.
                      </Body>
                      <AnimateRotate
                        style={{ alignSelf: "flex-start" }}
                        left={true}
                      >
                        <img
                          style={{ width: 30, alignSelf: "flex-start" }}
                          src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/star2.png"
                          alt="star"
                        />
                      </AnimateRotate>
                      <StyledButton
                        onClick={() => {
                          setModalVisible(true, heroVideo);
                          linkClickHandler(
                            heroVideo,
                            "Hero Video",
                            "internal_link"
                          );
                        }}
                      >
                        Watch Video
                      </StyledButton>
                    </Wrapper>
                  </AnimatePop>
                </CTAContent>
              )}
            </CTA>
            <HeroImg
              onLoad={() => setLoaded(true)}
              isMobile={isMobile}
              src={
                isMobile
                  ? "https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/heroMob.png"
                  : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/hero.png"
              }
            />
            {loaded && (
              <Hero>
                <CTABottom>
                  <AnimatePop>
                    <Wrapper download={!isMobile}>
                      {isMobile ? (
                        <SubHeading
                          style={{
                            textAlign: "center",
                            padding: 20,
                            maxWidth: 500,
                            marginTop: 30,
                          }}
                        >
                          Download the McDonald’s app and chill with a Frozen
                          Drink.
                        </SubHeading>
                      ) : (
                        <SubHeading>
                          Download the <br />
                          McDonald’s app
                          <br /> and chill with
                          <br /> a Frozen Drink.
                        </SubHeading>
                      )}

                      <StyledButton
                        download={true}
                        onClick={() => {
                          window.open(
                            "https://www.mcdonalds.com/us/en-us/download-app.html",
                            "_blank"
                          );
                          linkClickHandler(
                            "https://www.mcdonalds.com/us/en-us/download-app.html",
                            "Download app",
                            "external_link"
                          );
                        }}
                      >
                        Download
                      </StyledButton>
                    </Wrapper>
                  </AnimatePop>
                </CTABottom>
                <AnimationContainer>
                  <DrinksLeft>
                    <AnimateSlideRight>
                      <StyledImg src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/drinksLeft.png" />
                    </AnimateSlideRight>
                  </DrinksLeft>
                  <DrinksRight>
                    <AnimateSlideLeft>
                      <StyledImg src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/drinksRight.png" />
                    </AnimateSlideLeft>
                  </DrinksRight>
                </AnimationContainer>
              </Hero>
            )}

            {loaded && (
              <Footer>
                {footerHeading && (
                  <SubHeadingPostCampaign>
                    {ReactHtmlParser(footerHeading)}
                  </SubHeadingPostCampaign>
                )}
                <SubHeadingPostCampaign>
                  <a
                    href={privacyPolicy}
                    onClick={() =>
                      linkClickHandler(
                        privacyPolicy,
                        "MAX Privacy Policy",
                        "footer"
                      )
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  powered by{" "}
                  <a
                    href={MAXpage}
                    onClick={() =>
                      linkClickHandler(MAXpage, "MAX Homepage", "footer")
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Music Audience Exchange
                  </a>
                  .
                </SubHeadingPostCampaign>
                <CookieButton
                  onClick={() => {
                    if (window.Cookiebot) {
                      window.Cookiebot.renew();
                    }
                  }}
                >
                  Click Here to set Cookie Preferences.
                </CookieButton>
              </Footer>
            )}
          </Content>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9 play="true">
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  z-index: 2;
  max-width: 1500px;

  @media (max-width: 950px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 950px) {
  }

  @media (max-width: 950px) {
  }
`;

const HeroImg = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 40;
  margin: -90px 0 0px 0;

  @media (max-width: 530px) {
    margin: -60px 0;
  }
  @media (min-width: 530px) and (max-width: 950px) {
    margin: -110px 0;
  }
`;
const ItemsImg = styled.img`
  width: 70%;
  object-fit: cover;
  z-index: 20;
`;
const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: -200px 0px 60px -160px;
  z-index: 70;
  @media (max-width: 750px) {
    margin: 0px auto;
    padding-bottom: 50px;
  }
  @media (min-width: 751px) and (max-width: 950px) {
    margin: 0px 0 0 100px;
    padding-bottom: 50px;
  }
`;
const DrinksLeft = styled.div`
  width: 40%;
  z-index: 70;
  margin-left: 20%;
  @media (max-width: 950px) {
    margin: -11%;
    width: 83%;
  }
`;
const DrinksRight = styled.div`
  width: 43%;
  margin-left: -35%;
  z-index: 60;
  @media (max-width: 950px) {
    margin-left: -60%;
    width: 85%;
  }
`;
const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  background: url("https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/bgTop.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 950px) {
    background: url("https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/bgTopMob.jpg");
    padding-bottom: 70px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
const Hero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 50;
  background: url("https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/bgBottom.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 950px) {
    background: url("https://storage.googleapis.com/whitelabel-set-live.appspot.com/677-mcdonalds-willie-jones/bgBottomCrop.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    z-index: 30;
    padding-bottom: 60px;
  }
`;
const CTABottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 30px -140px 110px 200px;
  @media (max-width: 950px) {
    width: 100%;
    margin: 30px;
    justify-content: center;
    align-items: center;
  }
`;

const CTAContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -15%;
  padding: 0 150px 150px;
  z-index: 10;

  p {
    text-align: center;
  }
  @media (max-width: 950px) {
    padding: 20px;
  }
`;
const Motiondiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  p {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ download }) =>
    download &&
    css`
      align-items: flex-start;
    `}/* @media (max-width: 950px) {
    width: 100%;
    text-align: center;
    margin: 40px auto;
  } */
`;

const VideoContainer = styled(VideoPlayer)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
  }
`;

const LogoBox = styled.div`
  margin: 50px 50px 0;
  display: flex;
  width: 100%;
  max-width: 70px;
  align-self: center;

  @media (max-width: 950px) {
    margin: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  padding: 30px;
  width: 100%;
  text-align: center;

  @media (max-width: 950px) {
    margin: 0;
    padding: 20px;
    width: 95%;
  }
`;

const StyledLink = styled.a`
  max-width: 100px;

  @media (max-width: 950px) {
    max-width: 50px;
  }
`;

const StyledButton = styled(ButtonPrimary)`
  width: 40%;
  max-width: 350px;
  height: 45px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 7px 1px 12px 1px; */
  margin: 0px auto 0;
  border-radius: 3px;

  ${(props) =>
    props.download && "width: 270px; margin: 20px 0 0; align-self: center"}

  @media (max-width: 950px) {
    font-size: 1rem;

    width: 270px;
  }

  & > a {
    color: black;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const Heading = styled.h2`
  font-size: min(3.5vw, 60px);
  margin-bottom: 10px;

  @media (max-width: 950px) {
    font-size: 40px;
  }
`;

const SubHeading = styled.h3`
  font-size: min(3vw, 45px);
  margin-bottom: 0px;

  @media (max-width: 950px) {
    font-size: max(3vw, 30px);
  }
`;

const Body = styled.p`
  color: black;
  font-size: min(1.2vw, 16px);
  line-height: 140%;
  display: block;
  width: 70%;
  text-align: left;

  @media (max-width: 950px) {
    padding: 0 50px;
    font-size: 0.8rem;
    line-height: 150%;
    width: 100%;

    div {
      font-size: 1rem;
      text-decoration: underline;
    }
  }

  & > span {
    display: inline-block;
    font-size: 1.4rem;

    @media (max-width: 950px) {
      font-size: 1rem;
    }
  }
`;

const SubHeadingPostCampaign = styled.h3`
  font-weight: 200;
  font-size: 16px;
  color: black;
  text-align: left;
  margin-bottom: 5px;
  font-family: "Speedee_RegCd";

  @media (max-width: 950px) {
    text-align: center;
    font-size: 16px;
    font-weight: 100;
  }

  & > a {
    color: black;
  }
`;
