import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

export const AnimateRotate = ({ children, left }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animationControls = useAnimation();
  // console.log("load", loading);
  useEffect(() => {
    if (isInView) {
      animationControls.start("visible");
    }
  }, [isInView]);

  return (
    <Motiondiv ref={ref} left={left}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 5, scale: 0.1, rotate: left ? -180 : 180 },
          visible: { opacity: 1, y: 0, scale: 1, rotate: 0 },
        }}
        initial="hidden"
        animate={animationControls}
        transition={{ duration: 0.6, delay: 0.8 }}
      >
        {children}
      </motion.div>
    </Motiondiv>
  );
};
const Motiondiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  z-index: 10;
  margin-left: auto;

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
    `}
`;
