import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

export const AnimatePop = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animationControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      animationControls.start("visible");
    }
  }, [isInView]);

  return (
    <Motiondiv ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75, scale: 0.7 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animationControls}
        transition={{ duration: 0.2, delay: 0.25 }}
      >
        {children}
      </motion.div>
    </Motiondiv>
  );
};
const Motiondiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
  p {
    text-align: center;
  }
`;
